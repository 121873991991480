.tr__somatablebody--conteiner{
    width: 100%;
    transition: all 0.2s ease-in-out;
}

.tr_lineHighlighted{
    background-color: #FFFAE8;
}

.tr__somatablebodyactive--conteiner{
    background-color: #FAF7F4;
}

.tr__somatablebodydisabled--conteiner{
    background-color: #F9F9F9;
    opacity: 0.65;
}

.td__item--conteiner{
    vertical-align: middle;
    text-align: left;
    padding: 28px 14px;
    color: #3A3A3A;
    font-family: 'flama-medium';
    max-height: 80px;
    font-size: 0.875rem;
}

.td__item--conteiner:first-child{
    padding-left: 24px;
}

.td__columnHighlighted{
    color: #E3BE25;
}

.td__item--conteinerhighlighted{
    color: #707070;
    font-size: 1rem;
    padding-right: 20px;
}

.td__item--conteinerbutton{
    padding-left: 12px;
    padding-right: 12px;
    width: 100px;
}

.tr__somatablebody--conteiner:active,
.tr__somatablebody--conteiner:hover{
    background-color: #F9F9F9;
}

@media(max-width:767px) {
    .td__item--conteiner:first-child{
        padding-left: 16px;
    }
    
    .td__item--conteiner{
        padding: 28px 8px;
        font-size: 0.75rem;
    }

    .td__item--conteinerhighlighted{
        padding-right: 20px;
        font-size: 0.875rem;
    }
}