.button__TableButtonDownload--container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EAE3DD;
    color: #8B7055;
    padding: 6px 12px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    transition: background-color 0.4s ease;
    border-radius: 4px;
    white-space: nowrap;
}

.button__TableButtonDownload--container:disabled{
    cursor: not-allowed;
}

.button__TableButtonDownload--container > svg{
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.button__TableButtonDownload--container > span {
    font-size: 0.7rem;
    font-family: "flama-medium";
}

.button__TableButtonDownload--container:hover,
.button__TableButtonDownload--container:active{
    background-color: #AD9078;
    color: white;
}


.loader__TableButtonDownload {
    border: 4px solid white;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
