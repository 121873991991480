.div__HomeInfo--container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #000000;
}

.p__lojinha--text {
    font-family: 'flama-basic';
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 27px;
    color: #000000;
    margin: 24px auto 54px;
    text-align: left;
    width: 60%;
}

.a__clickhere {
    font-weight: 700;
    text-decoration: underline;
    color: #000000;
}

.a__clickhere:hover {
    opacity: .7;
}

@media (max-width: 1024px) {
    .p__lojinha--text {
        width: 95%;
        text-align: center;
        font-size: 1.125rem;
    }
}