.div__somatable--conteiner {
    overflow: auto;
    margin: 0 auto;
    width: 100%;
}

.table__somatable--conteiner {
    border-radius: 4px;
    border-collapse: collapse;
    background-color: white;
    height: 100%;
    width: 100%;
}