.container {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
}

.progressBar {
    height: 18px;
    background-color: #AD9078;
    border-radius: 10px;
}

.description {
    margin: 0;
    text-align: center;
}