.icone {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto;
  vertical-align: middle;
}

.sizeIcone_small {
  width: 10px;
  height: 10px;
  margin: 0;
  margin-right: 5px;
}