.Historic {
    display: flex;
    flex-direction: column;
    background: #FFF;
    border-radius: 14px;
    margin-top: 10px;
    padding: 20px;
    align-content: center;
    height: 50vh;
    overflow: auto;
}

.Historic::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #dcdddd;
}

.Historic::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #9A9A9A;
}

.Detail {
    color: #333333;
    display: flex;
    font-weight: 550;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    font-size: 1rem;
    font-family: flama-medium;
}

.Detail>h4 {
    margin-top: 0;
}

.groupInfo {
    margin-bottom: 20px;
}

.topic {
    display: block;
    font-size: 0.75rem;
    font-family: 'flama-medium';
    color: #838383;
    margin-bottom: 3px;
}

.message {
    display: block;
    font-size: 0.875rem;
    font-family: flama-basic;

}

.buttonMessage {
    border: none;
    font-size: 11px;
    margin-left: 5px;
    text-decoration: underline;
    color: #AD9078;
    background-color: transparent;
    cursor: pointer;
}

.buttonMessage:active {
    transform: translateY(4px);
}

@media(min-width:700px) {
    .CardBox {
        border-radius: 8px;
        height: min-content;
    }

    .boxDetail {
        gap: 12px;
    }

}

@media(min-width:1600px) {
    .CardBox {
        max-width: 90%;
    }

}