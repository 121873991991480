.h4__warning--text {
    color: #474747;
    font-family: 'flama-book';
    font-size:0.875rem;
    margin: 0 0 25px 0;
    line-height: 22px;
    font-weight: normal;
    text-align: center;
}

.div__confirmcredentialsmodal--content {
    margin: 0 auto;
    width: 69%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div__inputconfirmcredentialsmodal--container {
    margin-bottom: 47px !important;
}