.main__home--container{
    display: flex;
    height: 100%;
}   

.button__popup_saiba-mais{
    position: absolute;
    width: 20.5%;
    height: 3.2%;
    top: 21.3%;
    left: 11%;
    border: none;
    background-color:transparent;
    cursor: pointer;
}

.button__popup_saiba-mais-mobile{
    position: absolute;
    width: 26.5%;
    height: 3.2%;
    top: 20.2%;
    left: 11.5%;
    border: none;
    background-color:transparent;
    cursor: pointer;
}
