.main__loginpage--conteiner{
    display: grid;
    grid-template-areas: "img form";
    grid-template-columns: 40% auto;
    background-color: #FBFBFB;
    min-height: 100%;
}

.div__leftside--conteiner{
    grid-area: img;
    position: relative;
    background: top/cover no-repeat;
}

.div__leftside--conteiner::after{
    content: "";
    position: absolute;
    right: -1px;
    height: 100%;
    width: 110px;
    background: url('../../assets/images/Background/BackgroundLoginPage/wave.svg') left/cover no-repeat;
}

.div__leftsideresponsive--conteiner{
    display: none;
}

@media(max-width:1023px){
    .main__loginpage--conteiner{
        grid-template-areas: 'form'
                             'img';
        grid-template-columns:none;
        grid-template-rows: auto
                            350px;
    }

    .div__leftsideresponsive--conteiner{
        grid-area: img;
        display: block;
        width: 100%;
        background: top/cover no-repeat;
        position: relative;
    }

    .div__leftsideresponsive--conteiner::after{
        content: "";
        position: absolute;
        top: -1px;
        background: url('../../assets/images/Background/BackgroundLoginPageResponsive/waveResponsive.svg') bottom/cover no-repeat;
        width: 100%;
        height: 45%;
    
    }

    .div__leftside--conteiner{
        display: none;
    }
}

@media(max-width:768px){
    .div__leftsideresponsive--conteiner::after{
        height: 37%;
    }
}

@media(min-height:860px){
    .div__leftside--conteiner::after{
        width: 40%;
    }
}

.div__rightside--conteiner{
  padding: 16px 0;
  grid-area: form;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer__rightside--conteiner{
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer__rightside--conteiner > small{
  color: #B2B2B2;
  font-family: 'flama-book';
  font-size: 0.75rem;
}

.footer__rightside--conteiner > span{
  color: #707070;
  font-family: 'flama-book';
  font-size: 0.75rem;
  margin:  0 40px 0 16px;
}

@media(max-width:1023px){
  .div__rightside--conteiner{
      padding: 48px 0 0 0 ;
  }

  .footer__rightside--conteiner{
      display: none;
  }
}
