.div__HomeNPS--backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.45);
}
  
.div__HomeNPS--container {
    position: fixed;
    bottom: 0;
    padding: 20px 25px;
    background-color: white;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.25);
    text-align: start;
}
  
.p__HomeNPS--text {
    font-family: flama-basic;
    color: #616161;
    font-size: 0.85rem;
    text-align: start;
    max-width: 360px;
}
  
.div__HomeNPS--ratingContainer {
    display: grid;
    justify-content: center;
    user-select: none;
}

.div__HomeNPS--ratingText {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 0.75rem;
    line-height: 0;
    margin-bottom: 30px;
    margin-top: 5px;
    padding-right: 2px;
}
  
.button_HomeNPS--buttonActivated,
.button_HomeNPS--buttonDeactivated {
    background-color: #dbc7b8;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    margin-top: 30px;
    font-family: flama-book;
    font-weight: 600;
    font-size: 0.85rem;
    cursor: pointer;
}

.button_HomeNPS--buttonActivated{
    color: #424242;
}
.button_HomeNPS--buttonDeactivated {
    background-color: #bcbcbc;
    color: #656565;
    cursor: default;
}
  
.button__HomeNPS--backButton {
    display: flex;
    align-items: center;
    margin: 10px 0 15px;
    background-color: transparent;
    border: none;
    font-family: flama-book;
    font-size: 0.75rem;
    color: #bdbdbd;
    padding: 0;
    cursor: pointer;
}
  
.textarea_HomeNPS--text {
    width: 100%;
    font-family: flama-book;
    font-size: 0.85rem;
    padding-left: 5px;
    resize: none;
}
  
.button__HomeNPS--categoryButtonActivated,
.button__HomeNPS--categoryButtonDeactivated {
    border-radius: 100px;
    font-family: flama-book;
    border: 1px solid #616161;
    padding: 10px 14px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
  
.button__HomeNPS--categoryButtonActivated {
    background-color: #616161;
    color: white;
}
  
.button__HomeNPS--categoryButtonDeactivated {
    color: #616161;
    background-color: transparent;
}
  
.div__HomeNPS--sentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.div__HomeNPS--closeSentContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
  
.div__HomeNPS--closeSentContainer svg {
    cursor: pointer;
}
  
.p__HomeNPS--textSent {
    font-family: flama-basic;
    color: #616161;
    font-size: 0.95rem;
    text-align: center;
    max-width: 360px;
}
  