.div__somafileuploader--container{
    display: flex;
    justify-content: center;
    border-style: dashed;
    border-color: #CECECE;
    border-radius: 8px;
}

.div__somafileuploader--container:hover{
    scale: 1.01;
}

.input__somafileuploader--hideUpload {
    display: none;
}

.icon__somafileuploader--upload-icon{
    transform: scale(2.5);
    margin-bottom: 20px;
}

.label__somafileuploader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
    width: 100%;
    padding: 40px 16px;
}

.div__somafileuploader--sent-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F2F2F2;
    padding: 30px;
    gap: 20px;
}

.div__somafileuploader--sent-info-container{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.div__somafileuploader--pdf-icon{
    transform: scale(1.5);
}

.img__somafileuploader--close-icon:hover{
    cursor: pointer;
}

.div__somafileuploader--sent-info{
    word-break: break-all;
    width: 100%;
}

.div__somafileuploader--sent-info > h4,
.div__somafileuploader--sent-info > p{
    margin: 5px 0;
}

.div__somafileuploader--completed{
    width: 100%;
    height: 4px;
    background-color: #AD9078;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    margin-top: 20px;
}
