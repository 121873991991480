.ul__SideMenuListItems--conteiner {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

.ul__SideMenuListItems--conteiner>li {
    margin-bottom: 4px;
}

.li__logoatacado--conteiner>div {
    padding: 6px 0 6px 17px;
    width: 100px;
}

.li__logoatacado--conteiner>div>img {
    width: 100%;
    height: 100%;
}

.a__homeItem--conteiner {
    padding: 6px 0 6px 17px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.image__Item {
    padding: 2px 13px 0 13px;
}

.ul__Imagem--conteiner {
    display: flex;
    padding: 0;
    flex-direction: column;
    gap: 18px;
}

.li__Imagem--item {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.a__homeItem--conteiner:hover {
    background-color: #F6F5F5;
}

.image__activedhomeItem--conteiner,
.a__activedhomeItem--conteiner,
.a__homeItem--conteiner:active {
    background-color: #DEDEDE;
}

.a__homeItem--conteiner>img {
    height: 22px;
    width: 22px;
    margin-right: 7px;
}

.a__homeItem--conteiner>span {
    font-family: 'flama-medium';
    font-size: 0.875rem;
    color: #707070;
    text-transform: uppercase;
}

@media(min-width:767px) {
    .li__logoatacado--conteiner {
        display: none;
    }
}