.ItemsRow {
    margin-bottom: 30px;
}

.ItemsRow h3 {
    color: #707070;
    font-family: 'flama-medium';
}

.ItemsRow--listarea {
    position: relative;
    overflow-x: hidden;
}

.ItemsRow--list {
    transition: all ease 0.5s;
}

.ItemsRow--left,
.ItemsRow--right {
    position: absolute;
    width: 40px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    transition: all ease 0.5s;
}

.ItemsRow--left {
    left: 0;
}

.ItemsRow--right {
    right: 0;
}

.ItemsRow:hover .ItemsRow--left,
.ItemsRow:hover .ItemsRow--right {
    opacity: 1;
}

@media (max-width: 760px) {

    .ItemsRow--listarea {
        overflow-x: auto;
    }

    .ItemsRow:hover .ItemsRow--left,
    .ItemsRow:hover .ItemsRow--right {
        opacity: 0;
    }
}