.productsDetails {
    display: flex;
    color: #757575;
    font-family: flama-book;
    font-weight: 500;
    font-size: 0.875rem;
    width: 100%;
    line-height: 25px;
}

.productsDetailsHighlighted {
    color: #D66157;
    font-family: flama-book;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 25px;
}

.boxDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.productsDetails>.boxDetails:only-child {
    flex: 1 1 100%;
    width: 100%;
}

.lineThrough {
    margin: 0 5px;
    color: #BDBDBD;
    text-decoration: line-through;
}

@media(max-width:768px) {
    .productsDetails {
        flex-wrap: wrap;
    }

    .boxDetails {
        flex: 0 0 50%;
    }
}