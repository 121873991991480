.detailsBox {
    margin: 20px 30px 130px 30px;
}

.detailsBox>p {
    color: #666666;
    font-family: 'flama-medium';
    font-size: 1.2rem;
    margin-top: 0;
    padding-top: 0;

}

.unchecked {
    background-color: white !important;
}

.checked {
    background-color: #F5EFEA !important;
}

.itensInfo {
    display: flex;
    flex-wrap: wrap;
    background: #FFFFFF;
    border-radius: 14px;

    gap: 8px;
    padding: 0 20px;
    margin-bottom: 20px;
}

.itemInfo_highlighted {
    background-color: #FAEDEC
}

.itemInfo_gray {
    background-color: #F4F6F8
}

.MuiCheckbox-colorSecondary.Mui-checked {
    /*Checkbox color*/
    color: #148A6A !important;
}

.divergencyButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.divergencyButton>button {
    /*SecondaryButton*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: #A03127;
    border: 1px solid #A03127;
    border-radius: 10px;
    font-size: 0.875rem;
    font-family: 'flama-basic';
    text-transform: none;
    padding: 0;
}

.divergencyButtonColorRed>button {
    background-color: #A03127;
    border: solid 1px #A03127;
    color: white;
}

.boxDetailsText {
    color: #165A83;
    font-size: 0.875rem;
    font-family: 'flama-semibold';
}

.divergencyButtonContent {
    display: flex;
    place-items: center;
    place-content: center;
    width: 100%;
    padding: 10px 0;
}

.divergencyButtonContent>span {
    margin-left: 5px;
    font-size: 0.75rem;
}

.insertButtonContent {
    display: flex;
    place-items: center;
    place-content: center;
    width: 100%;
    padding: 10px 0;
}

.insertButtonContent>span {
    margin-left: 5px;
    font-size: 1rem;
    font-weight: 500;
    color: #A03127;
}

.insertButtonContent>svg {
    width: 12px;
}

@media(min-width:1025px) {
    .detailsBox {
        margin: 0;
        margin-bottom: 250px;
    }

    .itensInfo {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 12px;
    }
}

@media(min-width:900px) {
    .detailsBox>p {
        font-size: 1.6rem;
    }
}

.productInBoxAlert {
    font-weight: 400;
    color: #a8a8a8;
}

.productInBoxAlert> :link,
.productInBoxAlert> :visited,
.productInBoxAlert> :active {
    color: #AD9078;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
}

.divergencyButtonUpdate {
    justify-content: flex-start;
}

.divergencyButtonUpdate>button {
    color: #977055;
    border: 1px solid #977055;
}