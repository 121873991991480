.button__SecondaryButton--conteiner {
    padding: 12px 16px;
    color: #AD9078;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-family: 'flama-medium';
    border-radius: 4px;
    border: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.button__SecondaryButton--conteiner:active,
.button__SecondaryButton--conteiner:hover {
    box-shadow: 0px 3px 6px #00000029;
    background-color: #F8F2ED;
}

.button__SecondaryButton--conteiner:disabled {
    cursor: not-allowed;
    background-color: #E3E3E3;
    color: white;
}

.button__SecondaryButton--conteiner_inherit>* {
    color: inherit;
}