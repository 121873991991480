.Nps {
  padding: 5px;
  color: #424242;
  font-family: "flama-basic";
  margin: 20px 0;
  margin-bottom: 100px !important;
}

.Nps legend {
  font-size: 0.9rem;
  margin: 30px 0 5px 0;
}

.rating {
  display: grid;
  justify-content: center;
}

.rating span {
  font-size: 3rem;
}

.classification {
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  font-size: 0.75rem;
  text-align: center;

}

.classification p {
  margin: 0;

}

.textarea_nps {
  width: 100%;
  font-family: flama-book;
  font-size: 0.85rem;
  padding-left: 5px;
  padding-top: 3px;
  resize: none;
  border-radius: 8px;
}

@media (min-width: 767px) {
  .Nps {
    margin: 50px;
  }
}

@media (min-width: 1076px) {
  .Nps {
    margin: 40px 150px;
  }
}

@media (min-width: 1420px) {
  .Nps {
    margin: 40px 150px;
    padding: 0 10%;
  }
}