.div__somadataoverview{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #F4F6F8;
    font-family: flama-book;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.div__somadataoverview--text{
    color: #AD9078;
    font-weight: 600;
    font-family: flama-book;
}

.div__somadataoverview--container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    font-family: flama-medium;
}

.div__somadataoverview--title{
    font-size: 14px;
    color: #707070; 
    margin: 0;
}

.div__somadataoverview--subtitle{
    color: #707070;
    font-size: 10px;
    font-family: flama-basic;
    margin: 0;
}

.div__somadataoverview--values-container{
    display: flex;
    align-items: flex-end;
    padding: 20px 0 0 0;
    color: #3A3A40;
    font-family: flama-medium;
}

.div__somadataoverview--value-symbol{
    font-size: 12px;
    margin: 0;
}

.div__somadataoverview--value{
    font-size: 20px;
    margin: 0;
}

.div__somadataoverview--to-negotiate,
.div__somadataoverview--negotiated {
    margin-top: 12px;
    border: none;
    padding: 8px 12px;
    color: white;
    border-radius: 2px;
    font-family: flama-medium;
    cursor: pointer;
}

.div__somadataoverview--to-negotiate {
    background: #B82814;
}

.div__somadataoverview--negotiated {
    background: #B28877;
}

.div__somadataoverview--to-negotiate:hover,
.div__somadataoverview--negotiated:hover {
    opacity: .7;
}