.button__PrimaryButton--conteiner {
    padding: 12px 16px;
    color: white;
    background-color: #AD9078;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-family: 'flama-basic';
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button__PrimaryButton--conteiner:active,
.button__PrimaryButton--conteiner:hover {
    box-shadow: 0px 3px 6px #00000029;
    background-color: #9F856F;
}

.button__PrimaryButton--conteiner:disabled {
    background-color: #E3E3E3;
    cursor: not-allowed;
}