.logoPortal {
  width: 186px;
  margin-bottom: 32px;
  align-self: center;
}

.logoSoma {
  width: 75px;
  align-self: center;
}

.logoSoma>img {
  width: 100%;
  height: 100%;
}
