.form__updatecode--container {
    margin: 100px auto 0;
    padding: 20px;
    width: 500px;
}

.div__updatecodebutton--container {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}


@media(max-width:767px){
    .form__updatecode--container {
      width: 100%;
    }
}