.main {
  display: grid;
  grid-template-areas: "img form";
  grid-template-columns: 40% auto;
  background-color: #FBFBFB;
  min-height: 100%;
}

.background {
  grid-area: img;
  position: relative;
  background: top/cover no-repeat;
}

.background::after {
  content: "";
  position: absolute;
  right: -1px;
  height: 100%;
  width: 110px;
  background: url('../../assets/images/Background/BackgroundLoginPage/wave.svg') left/cover no-repeat;
}

.backgroundResponsive {
  display: none;
}

.children {
  padding: 16px 0;
  grid-area: form;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media(max-width:1023px) {
  .main {
    grid-template-areas: 'form' 'img';
    grid-template-columns: none;
    grid-template-rows: auto 350px;
  }

  .backgroundResponsive {
    grid-area: img;
    display: block;
    width: 100%;
    background: top/cover no-repeat;
    position: relative;
  }

  .backgroundResponsive::after {
    content: "";
    position: absolute;
    top: -1px;
    background: url('../../assets/images/Background/BackgroundLoginPageResponsive/waveResponsive.svg') bottom/cover no-repeat;
    width: 100%;
    height: 45%;
  }

  .background {
    display: none;
  }

  .children {
    padding: 48px 0 0 0;
  }
}

@media(max-width:768px) {
  .backgroundResponsive::after {
    height: 37%;
  }
}

@media(min-height:860px) {
  .background::after {
    width: 44%;
  }
}
