.div__somatexteditor--container{
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 16px;
}

.div__somatexteditor--container-buttons{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.button__somatexteditor{
    cursor: pointer;
    background: none;
    border: none;
    color: #616161;
    font-size: 16px;
    font-family: flama-book;
    line-height: 0;
}

.button__somatexteditor--active{
    color:#3A3A3A;
    font-weight: bold;
}

.button__somatexteditor--bold{
    font-weight: bold;
}

.button__somatexteditor--italic{
    font-style: italic;
}

.button__somatexteditor--underline{
    text-decoration: underline;
}

.button__somatexteditor--strike-through{
    text-decoration: line-through;
}

div.DraftEditor-root {
    overflow-y: auto;
    font-size: 16px;
    font-family: flama-book;
    margin: 15px 0 0 5px;
    height: 120px;
}

div.DraftEditor-root::-webkit-scrollbar{
    width: 8px;
    height: 80px;
    background-color: #dcdddd;
}

div.DraftEditor-root::-webkit-scrollbar-thumb{
    border-radius: 4px;
    background-color: #9A9A9A;
}