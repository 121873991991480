 /* Chrome, Safari, Edge, Opera */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }
 
 /* Firefox */
 input[type=number] {
   -moz-appearance: textfield;
 }

.tableRows {
    font-family: flama-book;
    width: 100%;
    color:#616161;
    font-size: 1rem;
    font-weight: 500;
    line-height: 27px;
    border-collapse:collapse;
 }
 
 .tableBody > tr:nth-child(odd) {
   background-color: #EEEEEE;
 }

 .checked .tableBody > tr:nth-child(odd) {
     background-color: #BDBDBD;
 }

.tableRow > td{
    padding: 4px 12px;
}

.quantityProduct{
    display: flex;
    align-items: center;
    justify-content: center;
}
.quantityProduct > input{
    text-align: center;
    background-color: transparent;
    border: none;
    width: 40px;
    font-family: 'flama-basic';
    font-size: 1rem;
}
.selectorButton{
    margin: 0 10px;
}

.selectorButton:active{
    transform: translateY(4px);
}

.buttonDesabled > path:first-child {
    fill: #D6D6D6;
}
.buttonDesabled:active{
    transform: translateY(0);
}

.strikethrough{
 
    color: #BDBDBD;
    text-decoration: line-through;
}

.gradeNumber{
    margin: 0 5px;
}

