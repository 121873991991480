.div__sidemenu--conteiner {
  display: none;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  min-width: 249px;
  padding-top: 16px;
  z-index: 1;
}

.div__sidemenu--conteiner.no-min-width {
  min-width: 0;
}

.button_hide_aside {
  height: 30px;
  padding: 0 11px 4px 11px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: calc(100% - 10px);
  margin: 0;
  color: #FFFF;
  font-weight: 600;
  font-family: flama-medium;
  background-color: rgb(207, 188, 173);
  display: none;
}

@media(min-width:1023px) {
  .button_hide_aside {
    display: block;
  }
}

@media(max-width: 767px) {
  .div__sidemenu--conteiner {
    display: none !important;
  }
}