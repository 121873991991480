.MuiToggleButton-root {
  border-radius: 15px !important;
  border: 1px solid#AD9078 !important;
  margin-right: 10px !important;
  color: #AD9078 !important;
  font-size: 10px !important;
}

.MuiToggleButton-label {
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 0.6875rem;
}

.Mui-selected {
  background-color: #AD9078 !important;
  color: #FFFFFF !important;
}

@media (max-width: 480px) {
  .MuiToggleButton-root {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .MuiToggleButtonGroup-root {
    flex-direction: column !important;
  }
}