.div__inputsloginform--conteiner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 108px;
    margin-bottom: 20px;
}

.div__inputsloginform--conteiner > input{
    height: 48px;
    padding: 0 15px;
    border-radius: 4px;
    background-color: #E9E9E9;
    color: #606060;
    border: none;
    outline: none;
    font-size: 0.875rem;
    font-family: "flama-book";
}

.div__inputsloginform--conteiner >input::placeholder {
    color: #919191;
}