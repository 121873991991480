.ItemsRow--item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    transform: scale(0.97);
    width: 184px;
    height: 105px;
    border-radius: 4px;
}

.ItemsRow--item:hover {
    transform: scale(1);
}

.ItemsRow--item>img {
    width: 100%;
    filter: brightness(95%);
    transition: all ease 0.2s;
}

.ItemsRow--item>span {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    font-family: 'flama-medium';
}

.ItemsRow--modal {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 180px;
    overflow: hidden;
    border-radius: 8px;
}

.ItemsRow--modal>span {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    font-family: 'flama-medium';
    width: 100%;
    font-size: inherit;
    line-height: inherit;
}

.ItemsRow--modal>img {
    width: 100%;
    height: 100%;
    filter: brightness(95%);
}

.ItemsRow--modal_divButton {
    display: flex;
    gap: 10px;
    width: 100%;
}

.button {
    width: 100%;
}

.button_secondary {
    border: 1px solid #B28877;
    border-radius: 8px;
}

.loader {
    border: 4px solid white;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 2s linear infinite;
    margin: auto;
}

.loader_secondary {
    border: 4px solid #c2a489;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 2s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media(min-width: 480px) {
    .ItemsRow--modal>img {
        height: auto;
    }

    .ItemsRow--modal>span {
        font-size: 28px;
        line-height: 45px;
    }

}