.div__backdrop--container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
}

.h4__warning--text {
    color: #474747;
    font-family: 'flama-book';
    font-size: 0.875rem;
    margin: 0 0 25px 0;
    line-height: 22px;
    font-weight: normal;
    text-align: center;
}

.div__modal__container {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    outline: none;
}

.img__btnclose--container {
    position: absolute;
    top: 23px;
    right: 21px;
    cursor: pointer;
}

.p__currentmodal--text {
    position: absolute;
    top: 23px;
    left: 21px;
    line-height: 0;
}

.main__contentmodal--container {
    padding: 30px 50px 20px;
    max-height: 80vh;
    overflow-y: auto;
    font-size: 14px;
    font-family: "flama-basic";
    color: #707070;
}

.main__contentmodal--container-2 {
    padding: 24px 24px 0;
    max-height: 80vh;
    overflow-y: auto;
}

.main__contentmodal--container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #dcdddd;
}

.main__contentmodal--container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #9A9A9A;
}

.h1__modaltitle--text {
    text-align: center;
    text-transform: uppercase;
    font-family: 'flama-semibold';
    font-size: 1.2rem;
    color: #000;
    /* padding: 20px 0 0; */
    line-height: 25px;
}

.footer__footermodal--container {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #F2F2F2;
    border-radius: 0px 0px 8px 8px;
}

.footer__footermodal--flexcontainer {
    display: flex;
    flex-direction: column;
}

.btn__primarybuttonspacing--element {
    position: relative;
    margin-right: 20px;
}

.btn__primarybutton--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #858585;
    border-radius: 50%;
    animation: button-loading-spinner .7s infinite linear;
}

.button__activeevent--hiddecontent {
    visibility: hidden;
    opacity: 0;
}

@keyframes button-loading-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media(max-width:2129px) {
    .div__modal__container {
        padding: 40px 0 0;
        width: 20%;
    }

    .footer__footermodal--container {
        padding: 32px 0;
    }
}

@media(max-width:1529px) {
    .div__modal__container {
        padding: 32px 0 0;
        width: 35%;
    }
}

@media(max-width:980px) {
    .div__modal__container {
        width: 40%;
        padding: 22px 0 0;
    }
}

@media(min-width:375px) {
    .h1__modaltitle--text {
        line-height: 30px;
    }
}

@media(max-width:540px) {
    .div__modal__container {
        width: 80%;
    }

    .footer__footermodal--container {
        padding: 24px 0;
    }

}