.div__twovalue--conteiner{
    display: flex;
    flex-direction: column;
}

.span__twovalue--value{
    font-size: 0.75rem;
    color:#858585;
    font-family: 'flama-book';
}

.span__twovaluehighlighted--value{
    font-size:0.875rem;
    color: #989898;
}

@media (max-width:767px) {
    .span__twovalue--value{
        font-size: 0.625rem;
    }

    .span__twovaluehighlighted--value{
        font-size:0.75rem;
    }
}