.li__menuitem--conteiner{
    padding: 6px 0 6px 17px;
    display: flex; 
    align-items: center;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.li__menuitem--conteiner:hover {
    background-color: #F6F5F5;
}

.li__menuitemActived--conteiner,
.li__menuitem--conteiner:active {
    background-color: #DEDEDE;
}

.li__menuitem--conteiner > img {
    height: 22px;
    width: 22px;
    margin-right: 7px;
}

.li__menuitem--conteiner > span {
    font-family: 'flama-medium';
    font-size: 0.875rem;
    color: #707070;
    text-transform: uppercase;
}

.ul__menusubitems--conteiner {
    display: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    list-style: none;
}

.a__menusubitems--item {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.a__menusubitems--item > li {
    padding: 6px 0 6px 47px;
    color: #000000;
    font-family: 'flama-book';
    font-size: 0.75rem;
    text-transform: uppercase;
    margin: 4px 0;
}

.a__menusubitemsactived--item > li{
    font-family: 'flama-medium';
}

.a__menusubitems--item:active {
    background-color: #DEDEDE !important;
}

.a__menusubitems--item:hover {
    background-color: #F6F5F5;
}