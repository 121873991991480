.button__TableButton--conteiner{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EAE3DD;
    color: #8B7055;
    padding: 6px 12px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    transition: background-color 0.4s ease;
    border-radius: 4px;
    white-space: nowrap;
}

.button__TableButton--conteiner:disabled{
    cursor: not-allowed;
}

.button__TableButton--conteiner > svg{
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.button__TableButton--conteiner > span {
    font-size: 0.7rem;
    font-family: "flama-medium";
}

.button__TableButton--conteiner:hover,
.button__TableButton--conteiner:active{
    background-color: #AD9078;
    color: white;
}

.button__TableButton--highlighted{
    background-color: #AD9078;
    color: white;
}

.button__TableButton--highlighted:hover,
.button__TableButton--highlighted:active{
    background-color: #EAE3DD;
    color: #8B7055;
}