.divergencyTag {
    border-radius: 4px;
    text-align: center;
    padding: 4px 12px;
    font-size: 0.7rem;
    color: white;
    font-family: "flama-basic";
    margin-bottom: 5px;
    margin-left: 2px;
    margin-right: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    white-space: nowrap;
}