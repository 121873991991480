.CardBox {
    background: #EEEEEE;
    border-radius: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-content: center;
}

.boxDetail {
    color: #333333;
    display: flex;
    font-weight: 550;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 0 20px;
    font-size: 1rem;
    font-family: flama-medium;
}

.leftSideName,
.rightSideName {
    display: flex;
}

.leftSideName>h4 {
    margin: 0;
}

.leftSideName>svg {
    width: 26px;
    height: 26px;
}

.rightSideName {
    background-color: transparent;
    border: none;
    align-items: center;
    color: #AD9078;
    font-size: 0.75rem;
    cursor: pointer;
}

.rightSideName:active {
    transform: translateY(4px);
}

.rightSideName>svg {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

.boxInfo {
    color: #757575;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
}

.boxInfoItem {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
}

.boxInfoItem>div {
    margin-top: 12px;
}

.boxInfoItemOneChild {
    width: 100%;
}

.boxInfo div>p {
    margin-bottom: 0;
}

.boxHighlighted {
    display: flex;
    justify-content: space-between;
    background-color: #B28877;
    padding: 10px 20px;
    color: white;
    font-size: 18px;
    font-family: 'flama-medium';
    border-radius: 0 0 14px 14px;
}

@media(min-width:700px) {
    .CardBox {
        border-radius: 8px;
        height: min-content;
    }

    .boxDetail {
        gap: 12px;
    }

}

@media(min-width:1600px) {
    .CardBox {
        max-width: 90%;
    }

}