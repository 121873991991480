.footer {
  min-height: 64px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 40px 0 40px;
  /* background-color: #fff; */
}

.footer>small {
  color: #B2B2B2;
  font-family: 'flama-book';
  font-size: 0.75rem;
}

.footer>span {
  color: #707070;
  font-family: 'flama-book';
  font-size: 0.75rem;
}

@media(max-width:1023px) {
  .footer {
    display: none;
  }
}
