.div__titleSelector--checkboxes-container {
    display: flex;
    border: 1px solid #757575;
    border-radius: 3px;
}

.div__titlesSelector--checkboxes-containeraaaaaa {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    border: 1.5px solid #EEEEEE;
    border-radius: 3px;
}

.div__titleSelector--checkbox {
    display: flex;
    font-size: 12px;
    align-items: center;
    gap: 10px;
    width: 50%;
}

.div__titlesSelector--resume {
    display: flex;
    justify-content: space-between;
    background-color: #F7F2ED;
    padding: 0 20px;
    border-radius: 0 0 4px 4px;
    color: #333333;
    font-family: flama-medium;
}