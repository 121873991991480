.login {
  width: 448px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 4px;
}

.form {
  width: 100%;
}

.passwordReset {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.passwordReset>span {
  font-size: 0.75rem;
  color: #6C6C6C;
  font-family: "flama-book";
}

.passwordReset .clickHere {
  color: #454545;
  font-family: "flama-medium";
  text-decoration: underline;
  cursor: pointer;
}

.form>button {
  width: 100%;
  text-align: center;
  height: 48px;
  color: white;
  background-color: black;
  font-size: 0.75rem;
  font-family: "flama-semibold";
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.form>button:hover,
.form>button:active {
  background-color: #212121;
}

.orLine {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.orLine>div {
  height: 1px;
  background-color: #B1B1B1;
  width: 45%;
}

.orLine>small {
  font-family: "flama-medium";
  font-size: 0.875rem;
  color: #404040;
}

.msLogin {
  padding: 0 14.5px;
  height: 48px;
  border: 2px solid #707070;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 48px;
}

.msLogin:active,
.msLogin:hover {
  background-color: #707070;
}

.msLogin>img {
  height: 24px;
  width: 24px;
}

.msLogin>span {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: "flama-semibold";
  color: #707070;
}

.msLogin:active>span,
.msLogin:hover>span {
  color: white;
}



@media(max-width:1023px) {
  .login {
    width: 85%;
  }
}
