.div__homeHeader--conteiner{
    background-color: #C6A489;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 14px;
}

@media(max-width:767px) {
    .div__homeHeader--conteiner{
        flex-direction: column;
    }
}