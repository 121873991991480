.productsList {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    margin-bottom: 200px;
}

.product>a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    cursor: pointer;
}

.leftContent {
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.leftContent>svg {
    margin-right: 12px;
    width: 15px;
    height: 19px;
}

.leftContent>div:first-child {
    margin-right: 12px;
    width: 15px;
}

.leftContent>div>p:first-of-type {
    font-weight: 500;
    color: #333333;
    font-size: 0.8rem;
    margin: 17px 0;
}

.rightContent {
    display: flex;
    align-items: center;
}

.rightContent>small {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 24px;
    font-weight: 500;
    font-size: 0.8rem;
    color: #333333;
}

.rightContent>svg {
    margin-right: 18px;
    width: 10px;
    height: 19px;
}

.productTitle {
    font-family: 'flama-basic';
    font-weight: normal;
    color: #333333;
    margin-bottom: 4px;
    margin-top: 0;
}