.h1__title--pages{
    text-align: center;
    font-size: 1.75rem;
    color: #707070;
    font-family: 'flama-medium';
    text-transform: uppercase;
    margin: 40px 0 56px;
}

@media (max-width: 767px){
    .h1__title--pages {
        font-size: 1.25rem;
    }
}
