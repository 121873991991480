.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.current_value {
    color: #B28877;
}

.container_range {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.input_range {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    accent-color: #B28877;
    height: 8px;
    margin: 12px 0;
    border-radius: 5px;
    outline: none;
}

/* WebKit browsers (Chrome, Safari) */
.input_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    background: #B28877;
    border-radius: 50%;
    margin-top: -8px;
}

/* Mozilla (Firefox) */
.input_range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #B28877;
    border-radius: 50%;
    margin-top: -8px;
}

/* Microsoft Edge and Internet Explorer */
.input_range::-ms-thumb {
    width: 25px;
    height: 25px;
    background: #B28877;
    border-radius: 50%;
    margin-top: -8px;
}

/* WebKit browsers (Chrome, Safari) */
.input_range::-webkit-slider-runnable-track {
    background: #D9D9D9;
    border-radius: 20px;
    height: 0.5rem;
}

/* Mozilla (Firefox) */
.input_range::-moz-range-track {
    background: #D9D9D9;
    border-radius: 20px;
    height: 0.5rem;
}

/* Microsoft Edge and Internet Explorer */
.input_range::-ms-track {
    background: #D9D9D9;
    border-radius: 20px;
    height: 0.5rem;
}