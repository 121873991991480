.containerFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
    background-color: #F2F2F2;
    min-height: 100px;
    padding: 5px 14px;
    position: fixed;
    bottom: 0;
}

.containerFooterScroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #F2F2F2;
    min-height: 100px;
    padding: 5px 14px;
}

.buttonFooter {
    width: 90%;
    padding: 16px;
    font-size: 0.9rem;
}

@media (min-width: 577px) {
    .hide {
        display: none;
    }
}

@media (max-width: 767px) {
    .containerFooter {
        width: 100%;
        left: 0;
    }
}

@media (min-width: 739px) {
    .buttonFooter {
        width: 80%;
    }
}