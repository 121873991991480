.div__tablefeature--conteiner,
.div__tableFilters--conteiner {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
}

.div__tablefeature--conteiner {
    justify-content: space-between;
    align-items: center;
}

.div__tableFilters--conteiner {
    justify-content: flex-end;
    gap: 10px;
}


@media (max-width: 760px) {
    .div__tableFilters--conteiner {
        justify-content: space-between;
    }

    /* .div__tablefeature--conteiner {
        flex-direction: row-reverse;
        align-items: flex-start;
    } */
}