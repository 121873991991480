.buttonsGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.div__somadataoverview--to-negotiate,
.div__somadataoverview--negotiated {
    border: none;
    padding: 8px 12px;
    color: white;
    border-radius: 2px;
    font-family: flama-medium;
    cursor: pointer;
}

.div__somadataoverview--to-negotiate {
    background: #B82814;
}

.div__somadataoverview--negotiated {
    background: #B28877;
}

.div__somadataoverview--to-negotiate:hover,
.div__somadataoverview--negotiated:hover {
    opacity: .7;
}