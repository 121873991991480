.div__sidemenuresponsive--conteiner{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 55%;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    min-width: 249px;
    background-color: white;
    padding-top: 16px;
    z-index: 9999;
}

@media(min-width:1023px){
    .div__sidemenuresponsive--conteiner {
      display: none !important;
    }
}