@media(max-width:767px) {
    .div__form--conteiner {
        align-items: center !important;
        flex-flow: column !important;
        justify-content: center;
    }

    .div__input--items {
        width: 100%;
        max-width: none !important;
    }
}