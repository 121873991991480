.div__HomeHeaderItem--container{
    margin: 16px;
    min-width: 132px;
}

.h3__HomeHeaderItem--title{
    margin:0 0 9px 0;
    font-family: 'flama-book';
    font-size: 0.938rem;
    color: rgba(255,255,255,0.8);
}

.h1__HomeHeaderItem--value{
    margin: 0;
    font-family: flama-semicond semibold;
    font-size: 1.5rem;
    color: white;
}



@media(max-width:767px) {
    .h3__HomeHeaderItem--title{
        font-size: 0.8rem;
    }

    .h1__HomeHeaderItem--value{
        font-size: 1.2rem;
    }
}