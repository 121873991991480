.div__homeconteudos--container {
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-top: 80px;
    width: 100%;
    height: 100%;
}

.div__homeconteudos--infocontainer{
    position: relative;
    width: 100%;
}

.img__homeconteudos--background{
    width: 100%;
}

.div__homeconteudos--button {
    position: absolute;
    top: 80%;
    left: 18%;
    transform: translate(-50%, -50%);
    background-color: #65259F; 
    width: 26%;
    height: 80px;
    border: none;
    border-radius: 50px;
    font-size: 1.318vw;
    font-weight: bold;
    font-family: flama-semibold;
    color: white;
    text-align: center;
    cursor: pointer;
    z-index: 10;
}

.img__homeconteudos--background-mobile{
    display: none;
}

@media(max-width:767px) {
    .div__homeconteudos--button{
        top: 35%;
        left: 130px;
        width: 200px;
        height: 40px;
        font-size: 14px;
        margin-top: 20px;
    }

    .img__homeconteudos--background{
        display: none;
    }

    .img__homeconteudos--background-mobile{
        display: flex;
        width: 100%;
    }

}