
.div__cardtag--container{
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #F2F2F2;
}

.p__cardtag--productname{
    font-weight: 600;
    margin: 10px 0px 0px 0px;
}

.p__cardtag--option{
    font-size: 12px;
    margin-top: 0px;
}

.div__cardtag--infocontainer{
    display: flex;
    gap: 10px;
    font-size: 12px;
}