/*NfeNumber NfeBox*/
.container {
    display: block;
    opacity: 1;
    margin-bottom: 200px;
    padding: 0px 80px;
}

.divCardTagContainerScroll {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.divCardTagContainerScroll::-webkit-scrollbar {
    width: 8px;
    height: 80px;
    background-color: #dcdddd;
}

.divCardTagContainerScroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #9A9A9A;
}

.containerNfeBoxItems {
    padding: 0px 21px;
    margin: 0;
}

.textInfoNfe {
    margin: 15px 0 !important;
}

.textInfoNfe>p {
    margin: 35px 0 !important;
}

.textModalAlignCenter {
    text-align: center;
}

.errorNfe {
    color: red;
    font-family: 'flama-basic';
    font-size: 0.75rem;
}

.textSearch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.textSearch>span {
    margin-left: 15px;
}

.clearSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.clearSearch button {
    background-color: transparent;
    border: none;
    color: #757575;
    font-size: 0.75rem;
    vertical-align: bottom;
    cursor: pointer;
}

.clearSearch button>span {
    margin-left: 5px;
    text-decoration: underline;
}

.insertProductButton>button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0 10px;
    border: 1px solid #B82814;
    border-radius: 8px;
    font-size: 0.875rem;
    text-transform: none;
    box-shadow: 0px 0.4px 0px #c74433;
    padding: 12px;
}

.insertProductButton>button>a {
    font-family: 'flama-book';
    color: #B82814;
    text-decoration: none;
    font-weight: 600;
    height: 18.67px;
    display: flex;
    align-items: center;
}

.insertProductButton>button>a>svg {
    height: 13px;
    margin-right: 3px;
}

/*NfeItemEdit*/
.containerNfeItemEdit {
    margin-bottom: 150px !important;
}

/*Nfe Scanner*/
.selectCamera {
    position: fixed;
    z-index: 1;
    border-radius: 10px;
    padding: 10px;
    margin: 10px auto;
    border-color: #9F856F;
    background-color: rgba(255, 255, 255, 0.9);
}

.containerScanner {
    display: grid;
    width: 100%;
    height: 100%;
    place-items: center;
}

.containerScannerItems {
    grid-row-start: 1;
    grid-column-start: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0;
    opacity: 0.85;
}

.containerScannerItems p {
    font-weight: bold;
}

.formCheckboxRadio {
    margin: -5px 0;
    font-weight: 500;
    color: #707070;
    font-family: "flama-basic";
}

/* Acompanhamento */
.p__acompanhamento--message-info {
    line-height: 10px;
}

.div__acompanhamento--message {
    margin-top: 25px;
    font-size: 14px;
}

/*Back Button*/
.backButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 75px;
    padding: 13px 0 0 20px;
    color: #AD9078;
    background: none;
    border: none;
    cursor: pointer;
}

.backButton svg {
    transform: scaleX(-1);
    height: 15px;
    margin-right: 5px;
}

/*FooterButton*/
.displayButton {
    display: none;
}

.divContainerButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.divContainerButton>svg {
    margin-right: 10px;
}

@media (min-width: 577px) {
    .displayButton {
        display: block;
    }
}

@media (max-width: 767px) {
    .container {
        margin: 0;
        margin-bottom: 200px;
        padding: 0px 20px;
    }

    .divCardTagContainerScroll::-webkit-scrollbar {
        display: none;
    }

    .backButton {
        left: 20px;
    }
}

@media(min-width:1025px) {

    /*NfeBoxItems*/
    .containerBoxItems {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .containerNfeBoxItems {
        margin-left: 0;
        padding: 0px 25px;
    }
}