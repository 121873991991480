.titleIcon {
    display: flex;
    margin: 0;
}

.itemTitle {
    display: grid;
    grid-template-columns: 9fr 1fr;
    gap: 5px;
    width: 100%;
    margin-bottom: 8px;
    /*compensar o gap de 8px no .itensInfo */
}

.itemTitle>h5 {
    justify-self: start;
    font-family: 'flama-medium';
    color: #707070;
    font-size: 13px;
    margin: 0;
}