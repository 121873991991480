/* Visao inadimplencia */
.div__visaoInadimplencia--update {
    display: flex;
    margin-bottom: 15px;
}

.div__visaoInadimplencia--button {
    background: transparent;
    border: none;
    cursor: pointer;
}

.div__visaoInadimplencia--lastUpdated {
    font-size: 12px;
}

/* Page loading */
.div__pageloading-loadercontainer {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.div__pageloading-loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #AD9078;
    --_m:
        conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    to {
        transform: rotate(1turn)
    }
}

.div__pageloading-loadertext {
    font-size: 24px;
    color: #CFBCAD;
    font-family: flama-book;
    text-align: center;
}

/* Negociaçoes Faturas */
.div__negociacoesFaturas--container {
    font-family: flama-basic;
}

.p__negociacoesFaturas--info {
    font-size: 10px;
    color: #757575;
}

.p__negociacoesFaturas--questions {
    color: #757575;
}

.div__negociacoesFaturas--footer {
    display: flex;
    justify-content: flex-end;
    margin: 50px 0 10px 0;
    gap: 20px;
}

@media(max-width:600px) {
    .div__negociacoesFaturas--container {
        width: 100%;
    }
}

@media(min-width:1025px) {
    .div__negociacoesFaturas--container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}