.tr__somatabletitle--conteiner{
    background-color: #F4F6F8;
    position:sticky;
    top: 0;
    z-index: 99;
    height: 40px;
}

.th__somaTable--title:first-child{
    padding-left: 24px;
}

.th__somaTable--title{
    text-align: left;
    color: #9A9A9A;
    font-family: 'flama-semibold';
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: 0 16px;
    white-space: nowrap;
}

.th__somaTable--titlehighlighted{
    color: #707070;
    padding-right: 20px;
}

@media(max-width:767px) {
    .th__somaTable--title:first-child{
        padding-left: 16px;
    }
    
    .th__somaTable--title{
        padding: 0 8px;
    }

    .th__somaTable--titlehighlighted{
        padding-right: 20px;
    }
}