.div__hamburguer--button{
    height: 32px;
    width: 32px;
    cursor: pointer;
    display: none;
}

.div__hamburguer--button > img {
    width: 100%;
    height: 100%;
}

@media(max-width:1023px){
    .div__hamburguer--button{
        height: 28px;
        width: 28px;
        display: block;
    }

    .div__header--logo{
        display: none;
    }

    .div__header--userName{
        margin: 0 !important;
    }
}